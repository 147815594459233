import React from 'react'
import Header from './Header';
import axios from 'axios';
import './App.scss';
import Footer from './Footer';
import SingleBody from './SingleBody';
import 'bootstrap';

class SingleProduct extends React.Component{
    state = {
      general:{
        products:[],
        percentCompleted:1,
        categories:{"t_10":{"id":10,"name":"Hoodies & Sweatshirts","slug":"hoodies-and-sweatshirts-24"},"t_36":{"id":36,"name":"Eco Friendly","slug":"eco-friendly-36"}},
        langs:[{"id":1,"en":"Search products","ro":"Cauta produse"},{"id":2,"en":"List products","ro":"Listeaza produse"},{"id":3,"en":"RO","ro":"EN"},{"id":4,"en":"Ascending price","ro":"Pret crescator"},{"id":5,"en":"Descending price","ro":"Pret descrescator"},{"id":6,"en":"Products list","ro":"Lista produse"},{"id":7,"en":"Search","ro":"Cauta"},{"id":8,"en":"View details","ro":"Vezi detalii"},{"id":9,"en":"Change language","ro":"Schimba Limba"},{"id":10,"en":"Please type a product name","ro":"Tasteza numele unui produs"},{"id":11,"en":"Viewed products","ro":"Produse vizualizate"},{"id":12,"en":"No special characters allowed","ro":"Caracterele speciale nu sunt permise"},{"id":13,"en":"Error","ro":"Eroare"},{"id":14,"en":"Close","ro":"Inchide"}],
     
        currentLang:'ro',
        matchesFound:[]
      }
    }
    updateStateGeneral = (key, value) => {
      const general = {...this.state.general};
      general[key] = value;
      this.setState({general});
    }
    axiosGetRequest = (action, task) => {
        const $this = this;
        const client = axios.create({
          baseURL: 'http://localhost:8084/api/',
          timeout: 20000
        });
        let currentTask = task?'?task='+task:'';
        client.get(action+currentTask)
        .then(res => {
          if(action==='main_categories'){
            var resp = res.data.data;
            for (let index = 0; index < resp.length; index++) {
              $this.manageCats('t_'+resp[index].id, resp[index])
              
            }
          }
          if(action==='langs'){
            $this.updateStateGeneral('langs', res.data.data);
          }
        })
        .catch(function (error) {
          console.log(error);
       });   
    }
    manageCats = (key, value) =>{
      const general = {...this.state.general}
      general.categories[key] = value
      this.setState({general});
    }
    componentDidMount(){
        // this.axiosGetRequest('main_categories');
        // this.axiosGetRequest('langs');
    }
    render(){
        return (
            <div className="App">
            <Header 
                updateStateGeneral = {this.updateStateGeneral}
            />
            <SingleBody
              categories = {this.state.general.categories}
            /> 
            <Footer/>
            </div>
        )
    }
}
export default SingleProduct
