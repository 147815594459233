import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react'
import App from './App';
import NotFound from './NotFound';
import SingleProduct from './SingleProduct';

class Router extends React.Component{
   render(){
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={App}></Route>
                <Route path="/product/:slug" component={ SingleProduct }></Route>
                <Route component={ NotFound } ></Route>
            </Switch>
        </BrowserRouter>
        )
   }
}

export default Router
